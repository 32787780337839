<template>
  <section class="bg-blue-light text-center pad-y-60">
    <div class="container">
      <div class="heading" data-aos="fade-down">
        <h2 class="font-secondary text-teal-dark title fs-40">Om Swastyastu</h2>
      </div>

      <p
        class="font-weight-lighter font-italic mar-bottom-25"
        data-aos="fade-right"
        data-aos-delay="300"
      >
        Atas Asung Kertha Wara Nugraha Ida Sang Hyang Widhi Wasa / Tuhan Yang
        Maha Esa, kami bermaksud mengundang Bapak/Ibu/Saudara/i, pada acara
        Mepandes (Potong Gigi) putra-putri kami pada :
      </p>

      <div class="row">
        <div
          class="text-center col-md-4 mar-bottom-25"
          data-aos="fade-right"
          data-aos-delay="500"
        >
          <div>
            <img src="@/assets/icons/calendar.svg" />
            <div class="font-weight-lighter mt-3 text-uppercase text-muted">
              HARI / TANGGAL
            </div>
            <div class="font-weight-bold fs-18">
              {{ $parent.time.start.format("dddd, DD MMMM YYYY") }}
            </div>
          </div>
        </div>
        <div
          class="text-center col-md-4 mar-bottom-25"
          data-aos="fade-down"
          data-aos-delay="500"
        >
          <div>
            <img src="@/assets/icons/clock.svg" />
            <div class="font-weight-lighter mt-3 text-uppercase text-muted">
              WAKTU
            </div>
            <div class="font-weight-bold fs-18">
              {{ $parent.time.start.format("HH.mm") }} - {{ $parent.time.end }}
            </div>
          </div>
        </div>
        <div
          class="text-center col-md-4 mar-bottom-25"
          data-aos="fade-left"
          data-aos-delay="500"
        >
          <div>
            <img src="@/assets/icons/marker.svg" />
            <div class="font-weight-lighter mt-3 text-uppercase text-muted">
              Lokasi
            </div>
            <div class="font-weight-bold fs-18" v-html="$parent.location"></div>
          </div>
        </div>
      </div>

      <p
        class="font-weight-lighter font-italic"
        data-aos="fade-left"
        data-aos-delay="600"
      >
        Merupakan suatu kehormatan dan kebahagiaan bagi kami apabila
        Bapak/Ibu/Saudara/i berkenan hadir dan memberikan doa restu kepada
        putra-putri kami. Atas kehadiran serta doa restu Bapak/Ibu/Saudara/i,
        kami sekeluarga mengucapkan terima kasih. Kami yang berbahagia.
      </p>

      <div class="font-weight-lighter font-italic text-center text-small mb-n3">
        Keluarga
      </div>
      <h6
        class="text-teal-dark mb-0 mar-top-25"
        data-aos="fade-up"
        data-aos-delay="700"
      >
        Ni Made Sugiani
      </h6>
      <h6
        class="text-teal-dark mb-0 mar-top-10"
        data-aos="fade-up"
        data-aos-delay="700"
      >
        I Putu Wisdiana Putra
      </h6>
      <h6
        class="text-teal-dark mb-0 mar-top-10"
        data-aos="fade-up"
        data-aos-delay="700"
      >
        I Wayan Sandiyasa
      </h6>
      <h6
        class="text-teal-dark mb-0 mar-top-10"
        data-aos="fade-up"
        data-aos-delay="700"
      >
        I Putu Junaedi
      </h6>
      <h3
        class="font-secondary mb-0 mar-top-10"
        data-aos="fade-up"
        data-aos-delay="700"
      >
        Om Shanti Shanti Shanti om
      </h3>
    </div>
  </section>
</template>

<script>
export default {};
</script>
