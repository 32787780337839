<template>
  <div id="app">
    <transition name="overflow-fade">
      <div
        v-if="$root.ready && overflow"
        class="ready-overflow"
        @click="openWebsite()"
      >
        <div class="text-center w-100">
          <div class="title">Undangan {{ title }}</div>
          <span>from</span>
          <div class="mt-3 mb-2 small">Keluarga</div>
          <div class="mb-0 name">Ni Made Sugiani</div>
          <div class="mb-0 name">I Putu Wisdiana Putra</div>
          <div class="mb-0 name">I Wayan  Sandiyasa</div>
          <div class="mb-5 name">I Putu Junaedi</div>

          <button
            class="text-uppercase btn btn-outline-primary"
            @click="openWebsite()"
          >
            Buka Undangan
          </button>
        </div>
      </div>
    </transition>

    <AGWAudio ref="audio" :file="music.file" :loop="music.loop" :autoplay="music.autoplay"/>
    <AGWHero overlay :background="backgrounds" ref="hero" parallax target="#intro"/>

    <div class="bg-white agw-content" :style="{ 'margin-top' : `${$root.hero.height}px` }">
      <AGWIntro id="intro"/>

      <div class="pad-y-60">
        <!-- <AGWPawiwahan id="pawiwahan"/> -->
        <AGWMepandes id="mepandes" class="mar-top-60" :mepandes="mepandes"/>
      </div>

      <AGWCounter
        id="counter"
        class="pad-y-60"
        :background="background"
        :date="time.start"
      />
      <AGWGallery id="gallery" class="pad-y-60"/>
      <AGWMap
        :height="450"
        url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3944.8971162035546!2d115.09993031478372!3d-8.605875993815076!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zOMKwMzYnMjEuMiJTIDExNcKwMDYnMDcuNiJF!5e0!3m2!1sen!2sid!4v1621777297661!5m2!1sen!2sid"
        directionUrl="https://www.google.com/maps/dir//-8.605876,115.102119/@-8.605876,115.0999303,17z/data=!4m2!4m1!3e0"
        :location="location"
        :time="time"
        id="map"
      />
    </div>

    <div class="agw-content" style="background: transparent">
      <Himbauan />
      <attendance id="attendance" class="pad-y-60"></attendance>
    </div>

    <div :style="{ 'height' : `${$root.dividerHeight}px` }"></div>

    <section class="bg-white footer pad-y-20" ref="footer">
      <div class="font-weight-lighter">
        &copy; {{ $moment().format('YYYY') }}. Made by <a href="https://ceremonyku.com">ceremonyku</a>.
      </div>
      <div class="flex mt-2 mb-2 w-full justify-center">
        <a href="https://fb.com/ceremonyku" target="_blank" class="mx-2"
          ><svg
            id="facebook"
            xmlns="http://www.w3.org/2000/svg"
            width="26.694"
            height="26.694"
            viewBox="0 0 26.694 26.694"
          >
            <path
              id="Path_444"
              data-name="Path 444"
              d="M5.966,3H26.728a2.966,2.966,0,0,1,2.966,2.966V26.728a2.966,2.966,0,0,1-2.966,2.966H5.966A2.966,2.966,0,0,1,3,26.728V5.966A2.966,2.966,0,0,1,5.966,3M25.245,5.966H21.537a5.19,5.19,0,0,0-5.19,5.19v3.707H13.381v4.449h2.966V29.694H20.8V19.313h4.449V14.864H20.8V11.9a1.483,1.483,0,0,1,1.483-1.483h2.966Z"
              transform="translate(-3 -3)"
              fill="#283a60"
            ></path></svg
        ></a>
        <a
          href="https://www.instagram.com/ceremonyku/"
          target="_blank"
          class="mx-2"
          ><svg
            id="instagram"
            xmlns="http://www.w3.org/2000/svg"
            width="26.694"
            height="26.694"
            viewBox="0 0 26.694 26.694"
          >
            <path
              id="Path_443"
              data-name="Path 443"
              d="M9.741,2H20.953a7.747,7.747,0,0,1,7.741,7.741V20.953a7.741,7.741,0,0,1-7.741,7.741H9.741A7.747,7.747,0,0,1,2,20.953V9.741A7.741,7.741,0,0,1,9.741,2M9.474,4.669a4.8,4.8,0,0,0-4.8,4.8V21.22a4.8,4.8,0,0,0,4.8,4.8H21.22a4.8,4.8,0,0,0,4.8-4.8V9.474a4.8,4.8,0,0,0-4.8-4.8H9.474m12.88,2A1.668,1.668,0,1,1,20.686,8.34a1.668,1.668,0,0,1,1.668-1.668m-7.007,2a6.673,6.673,0,1,1-6.673,6.673,6.673,6.673,0,0,1,6.673-6.673m0,2.669a4,4,0,1,0,4,4,4,4,0,0,0-4-4Z"
              transform="translate(-2 -2)"
              fill="#283a60"
            ></path></svg
        ></a>
        <a href="https://wa.me/6285739137858" target="_blank" class="mx-2"
          ><svg
            id="whatsapp"
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 26 26"
          >
            <path
              id="Path_445"
              data-name="Path 445"
              d="M21.175,17.548c.325.169.533.26.6.39a2.559,2.559,0,0,1-.273,1.534,3.166,3.166,0,0,1-2.21,1.456c-.6.026-.611.468-3.848-.949s-5.187-4.875-5.343-5.1A6.3,6.3,0,0,1,8.9,11.49a3.543,3.543,0,0,1,1.235-2.652.969.969,0,0,1,.884-.338h.611c.2,0,.468-.078.715.585l.9,2.431a.593.593,0,0,1,.013.572l-.351.533-.507.546c-.156.156-.338.325-.156.65a9.648,9.648,0,0,0,1.716,2.314,8.59,8.59,0,0,0,2.535,1.69c.312.182.507.156.7-.052l1.053-1.222c.247-.325.455-.247.754-.143l2.171,1.144M15,2A13,13,0,1,1,8.045,25.985L2,28l2.015-6.045A13,13,0,0,1,15,2m0,2.6A10.4,10.4,0,0,0,6.5,20.993L5.25,24.75,9.007,23.5A10.4,10.4,0,1,0,15,4.6Z"
              transform="translate(-2 -2)"
              fill="#283a60"
            ></path></svg
        ></a>
      </div>
    </section>

    <transition name="slide-fade">
      <guest-box :floating="true" v-if="$root.scroll.Y > $root.hero.height"/>
    </transition>

    <div class="snackbar" v-if="sw.updateExists">
      New version available!
      <button @click="refreshApp" class="btn">REFRESH</button>
    </div>
  </div>
</template>

<script>
const ImageHero1 = 'https://ik.imagekit.io/aryraditya/ceremonyku/mepandes-beraban/MDP_3487.jpg?tr=w-800'
const ImageHero2 = 'https://ik.imagekit.io/aryraditya/ceremonyku/mepandes-beraban/MDP_3475.jpg?tr=w-800'
const ImageHero3 = 'https://ik.imagekit.io/aryraditya/ceremonyku/mepandes-beraban/MDP_3149.jpg?tr=w-800'

import Audio from "@/assets/audio/backsound.mp3";

import AGWHero from "@/components/Hero";
import AGWIntro from "@/components/Intro";
import AGWPawiwahan from "@/components/Pawiwahan";
import AGWMepandes from "@/components/Mepandes";
import AGWCounter from "@/components/Counter";
import AGWGallery from "@/components/Gallery";
import AGWMap from "@/components/Maps";
import AGWAudio from "@/components/Audio";
import GuestBox from "@/components/GuestBox";
import Himbauan from '@/components/Himbauan.vue';
import Attendance from "@/components/Attendance";

import { setInterval } from "timers";

export default {
  name: "app",
  components: {
    AGWHero,
    AGWIntro,
    AGWPawiwahan,
    AGWMepandes,
    AGWCounter,
    AGWGallery,
    AGWMap,
    AGWAudio,
    GuestBox,
    Attendance,
    Himbauan,
  },
  data() {
    return {
      title: 'Mepandes',
      backgrounds: [ImageHero1, ImageHero2, ImageHero3],
      bgActive: 0,
      dividerHeight: 0,
      overflow: true,
      wedding: {
        man: {},
        woman: {},
      },
      location: "Br. Beraban Gg Jepun III, Desa Beraban, Kec. Kediri, Tabanan",
      time: {
        start: this.$moment("2021-06-16 11:00"),
        end: "Selesai"
      },
      mepandes: [
        {
          name: 'Luh Putu Regita Permata Sari',
          image: 'https://ik.imagekit.io/aryraditya/ceremonyku/mepandes-beraban/MDP_3186.jpg?tr=w-679,h-679,fo-custom,cm-extract'
        },
        {
          name: 'I Made Anindya Bhadrika Surya',
          image: 'https://ik.imagekit.io/aryraditya/ceremonyku/mepandes-beraban/MDP_3116.jpg?tr=w-672,h-672,fo-custom,cm-extract'
        },
        {
          name: 'Ni Putu Keshya Revaliana Putri',
          image: 'https://ik.imagekit.io/aryraditya/ceremonyku/mepandes-beraban/MDP_3317.jpg?tr=w-678,h-678,fo-custom,cm-extract'
        },
        {
          name: 'Ni Putu Aulia Saputri',
          image: 'https://ik.imagekit.io/aryraditya/ceremonyku/mepandes-beraban/MDP_3271.jpg?tr=w-671,h-671,fo-custom,cm-extract'
        },
        {
          name: 'Ni Kadek Sasa Dewi',
          image: 'https://ik.imagekit.io/aryraditya/ceremonyku/mepandes-beraban/MDP_3208.jpg?tr=w-678,h-678,fo-custom,cm-extract'
        },
        {
          name: 'I Putu Manik Tirtayoga',
          image: 'https://ik.imagekit.io/aryraditya/ceremonyku/mepandes-beraban/MDP_3131.jpg?tr=w-676,h-676,fo-custom,cm-extract'
        },
      ],
      music: {
        file: Audio,
        autoplay: true,
        loop: true
      },
      sw: {
        refreshing: false,
        registration: null,
        updateExists: false
      }
    };
  },
  computed: {
    background() {
      return this.backgrounds[this.bgActive];
    }
  },
  mounted() {
    this.dividerHeight = `calc(100vh - ${this.$refs.footer.offsetHeight}px)`;
    setInterval(() => {
      let active = this.bgActive + 1;
      if (active >= this.backgrounds.length) {
        active = 0;
      }

      this.bgActive = active;
    }, 5000);
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    // if(navigator.serviceWorker) {
    //   navigator.serviceWorker.addEventListener("controllerchange", () => {
    //   if (this.sw.refreshing) return;
    //   // this.sw.refreshing = true;
    //   // window.location.reload();
    // });
    // }
  },
  methods: {
    showRefreshUI(e) {
      this.sw.registration = e.detail;
      this.sw.updateExists = true;
    },
    refreshApp() {
      this.sw.updateExists = false;
      if (!this.sw.registration || !this.sw.registration.waiting) {
        return;
      }
      this.sw.registration.waiting.postMessage("skipWaiting");
      this.sw.refreshing = true;
      window.location.reload();
    },
    openWebsite() {
      this.overflow = false;
      document.querySelector("#loading").classList.remove("active");
      if (this.$refs.audio) this.$refs.audio.play();
    }
  }
};
</script>

<style scoped>
.overflow-fade-enter-active {
  transition: all 1s ease;
}
.overflow-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.overflow-fade-enter, .overflow-fade-leave-to {
  opacity: 0;
}
</style>
